export default {
    toolbar: {
        items: [
            'heading',
            '|',
            'alignment',
            'bold',
            'italic',
            'link',
            'bulletedList',
            'numberedList',
            'blockQuote',
            'undo',
            'redo',
            'insertTable',
            'fileUploadInline'
        ]
    },
    link: {
        decorators: {
            openInNewTab: {
                mode: 'manual',
                label: 'Open in a new tab',
                defaultValue: false,
                attributes: {
                    target: '_blank',
                    rel: 'noopener noreferrer'
                }
            }
        }
    },
    table: {
        contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells']
    },
    heading: {
        options: [

            {
                model: 'paragraph',
                title: 'Paragraph',
                class: 'ck-heading_paragraph'
            },
            {
                model: 'heading1',
                view: 'h1',
                title: 'Heading 1',
                class: 'ck-heading_heading1'
            },
            {
                model: 'heading2',
                view: 'h2',
                title: 'Heading 2',
                class: 'ck-heading_heading2'
            },
            {
                model: 'heading3',
                view: 'h3',
                title: 'Heading 3',
                class: 'ck-heading_heading3'
            },
            {
                model: 'headingThreeBlue',
                view: {
                    name: 'h3',
                    classes: 'headingBlue'
                },
                title: 'Heading 3 (Blue)',
                converterPriority: 'high'
            },
            {
                model: 'heading4',
                view: 'h4',
                title: 'Heading 4',
                class: 'ck-heading_heading4'
            },
            {
                model: 'headingFourBlue',
                view: {
                    name: 'h4',
                    classes: 'headingBlue'
                },
                title: 'Heading 4 (Blue)',
                converterPriority: 'high'
            },
            {
                model: 'heading5',
                view: 'h5',
                title: 'Heading 5',
                class: 'ck-heading_heading5'
            },
            {
                model: 'heading6',
                view: 'h6',
                title: 'Heading 6',
                class: 'ck-heading_heading6'
            },
            {
                model: 'primaryButton',
                view: {
                    name: 'p',
                    classes: 'buttonPrimary',
                },
                title: 'Primary Button',
                converterPriority: 'high',
            },
            {
                model: 'secondaryButton',
                view: {
                    name: 'p',
                    classes: 'buttonSecondary',
                },
                title: 'Secondary Button',
                converterPriority: 'high',
            }
        ]
    }
}
